import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as styles from '../../../css/pages/Blog.module.scss';

import Layout from '../../../components/layout/Layout';
import SEO from '../../../components/layout/SEO';

import { formatDate } from '../../../util';

const StartupTemplate = () => {
    const data = useStaticQuery(graphql`
        {
            allMarkdownRemark(
                filter: {
                    fileAbsolutePath: { regex: "/(blog)/" }
                    frontmatter: { category: { eq: "Startup" } }
                }
                sort: { fields: frontmatter___date, order: DESC }
            ) {
                edges {
                    node {
                        frontmatter {
                            publish
                            category
                            title
                            shortTitle
                            path
                            date
                            featuredimage {
                                childImageSharp {
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const blogData = data.allMarkdownRemark.edges;

    return (
        <>
            <SEO
                title="Startup blog"
                description="Read our journey, our ups and downs building a startup and how we handle it all"
                path="/blog/startup/"
            />
            <Layout mainClass={styles.Blog}>
                <h1>Our experiences running a fintech startup</h1>
                <hr />
                <div className={styles.blogContainer}>
                    {blogData.map((blog, i) => {
                        return blog.node.frontmatter.publish ? (
                            <div key={`blog-${i}`} className={styles.blogStory}>
                                <Link to={blog.node.frontmatter.path}>
                                    <h2>
                                        {blog.node.frontmatter.shortTitle ||
                                            blog.node.frontmatter.title}
                                    </h2>
                                    <GatsbyImage
                                        image={
                                            blog.node.frontmatter.featuredimage
                                                .childImageSharp.gatsbyImageData
                                        }
                                        alt={`${blog.node.frontmatter.title}-featured-image`}
                                        className={styles.featuredImage}
                                    />
                                </Link>
                                <div className={styles.meta}>
                                    <p>
                                        {formatDate(
                                            blog.node.frontmatter.date,
                                            'short',
                                            'short'
                                        )}
                                    </p>
                                </div>
                            </div>
                        ) : null;
                    })}
                </div>
            </Layout>
        </>
    );
};

export default StartupTemplate;
